<script lang="ts" setup>
import {
  computed,
  toRef,
} from 'vue';
import { useRoute } from 'vue-router';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';

import { useRootNavigationStore } from '@core/navigation';

import { VLoaderDelayed } from 'web/src/components/Loader';

const store = useRootNavigationStore();
const isRouteContentLoading = toRef(store, 'isRouteContentLoading');
const route = useRoute();
const isLoading = computed(() => !!isRouteContentLoading.value[route.name as BaseRouteNameType]);
</script>

<template>
  <div v-auto-id="'NavigationMainContentLoaderRouteComponent'"
    v-show="isLoading"
    :class="$style['sport-event-loader-wrapper']"
  >
    <div :class="$style['loader-masked']">
      <VLoaderDelayed />
    </div>
  </div>
</template>

<style module lang="scss">
.sport-event-loader-wrapper {
  @include z-index('sport-loader');

  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  margin-top: 44px;
  background-color: var(--Layer0);

  @include is-app-layout-desktop {
    margin-top: 0;
  }
}

/* TODO refactor */
.loader-masked {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  background-color: var(--OpacityLayer0);
}
</style>
